<template>
  <en-drawer title="外包信息" :model-value="modelValue" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')">
      <en-form-item label="外包公司" prop="outsourcingCompany.id">
        <select-maintain
          v-model="form.data.outsourcingCompany"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (value) => ({ name: value, type: 'O' })
          }"
          :props="{ label: 'name', value: '' }"
          remote
          value-key="id"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="成本价" prop="cost">
        <en-input-number v-model="form.data.cost" :min="0" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.outsourcingComment" type="textarea"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { formMaintenanceInit } from '@service/common/workorder'

export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudServiceDefinitions['MaintenanceDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (value: any) => typeof value === 'object'
  },

  watch: {
    modelValue: {
      handler(visible) {
        if (visible) {
          Object.assign(this.form.data, this.data)
        } else {
          this.form.data = formMaintenanceInit()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        data: formMaintenanceInit(),
        rules: {
          'outsourcingCompany.id': { required: true, message: '请选择外包公司' },
          cost: { required: true, type: 'number', min: 0, message: '请填写成本价' }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            await this.refs.form.validate()
            this.emit('confirm', this.form.data)
            this.footer.cancel.click()
          }
        }
      }
    }
  }
})
</script>
